import React, { useEffect, useState, useContext } from "react";
import { Card, Col, Row } from "antd";
import Echart from "../../components/Layouts/sharedAdminLayout/partials/chart/EChart";
import LineChart from "../../components/Layouts/sharedAdminLayout/partials/chart/LineChart";
import PieChart from "../../components/Layouts/sharedAdminLayout/partials/chart/PieChart";
import AdminAnalayticsCard from "../../components/Layouts/sharedAdminLayout/partials/analytics-card";
import { DashboardContext } from "../../context/DashboardContext";
import {
  faUser,
  faUserCheck,
  faUsers,
  faUserSlash,
} from "@fortawesome/free-solid-svg-icons";
function AdminDashboard() {
  const { dashboardStats, getDashboardStats } = useContext(DashboardContext);

  let initAnalytics = [
    {
      title: "Total Pilots",
      thumb: faUser,
      amount: 0,
    },
    {
      title: "Total FAs",
      thumb: faUsers,
      amount: 0,
    },
    {
      title: "Total Clients",
      thumb: faUserCheck,
      amount: 0,
    },
    {
      title: "Total Jobs",
      thumb: faUserSlash,
      amount: 0,
    },
  ];
  const [analytics, setAnalytics] = useState(initAnalytics);


  // Bar Chart Options
  const [eChartData, setEChartData] = useState({
    labels:[],
    values:[],
    totalCrews:0,
    totalFAs:0,
    totalCaptains:0,
    totalOfficers:0,
  })

  // PieChart Options
  const [pieChartData, setPieChartData] = useState({
    labels:[],
    values:[],
  })


  useEffect(() => {
   getDashboardStats(); 
  },[]);

  useEffect(() => {
    if(dashboardStats){
      // setting analytics for top
      setAnalytics([
        {
          title: "Total Pilots",
          thumb: faUser,
          amount: dashboardStats.total_first_officer+dashboardStats.total_captain,
        },
        {
          title: "Total FAs",
          thumb: faUserCheck,
          amount: dashboardStats.total_fa?dashboardStats.total_fa:0,
        },
        {
          title: "Total Clients",
          thumb: faUsers,
          amount: dashboardStats.total_clients?dashboardStats.total_clients:0,
        },
        {
          title: "Total Jobs",
          thumb: faUserSlash,
          amount: dashboardStats.total_jobs?dashboardStats.total_jobs:0,
        },
      ]);


      // setting BarChart
      setEChartData({
        labels:dashboardStats.signups.labels?dashboardStats.signups.labels.reverse():[],
        values:dashboardStats.signups.values?dashboardStats.signups.values.reverse():[],
        totalCrews:dashboardStats.total_crews?dashboardStats.total_crews:0,
        totalFAs:dashboardStats.total_fa?dashboardStats.total_fa:0,
        totalCaptains:dashboardStats.total_captain?dashboardStats.total_captain:0,
        totalOfficers:dashboardStats.total_first_officer?dashboardStats.total_first_officer:0
      });


      // setting PieChart
      // parse int values
      var a = dashboardStats.referrals.values?dashboardStats.referrals.values:[];
      var results = a.map(function (x) { 
        return parseInt(x, 10); 
      });
      setPieChartData({
        labels:dashboardStats.referrals.labels?dashboardStats.referrals.labels:[],
        values:results,
      });
      
    }
  },[dashboardStats]);
 


  return (
    <>
      <div className="layout-content">
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          {analytics.map((item, index) => (
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={6}
              xl={6}
              className="mb-24"
              key={index}
            >
              <AdminAnalayticsCard
                title={item.title}
                thumb={item.thumb}
                total={item.amount}
              />
            </Col>
          ))}
        </Row>

        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={10} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <Echart eChart={eChartData} />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={14} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <PieChart pieChart={pieChartData}/>
            </Card>
          </Col>
        </Row>
        {/* 
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={16} className="mb-24">
            <Card bordered={false} className="criclebox cardbody h-full">
              <div className="project-ant">
                <div>
                  <Title level={5}>Projects</Title>
                  <Paragraph className="lastweek">
                    done this month<span className="blue">40%</span>
                  </Paragraph>
                </div>
                <div className="ant-filtertabs">
                  <div className="antd-pro-pages-dashboard-analysis-style-salesExtra">
                    <Radio.Group defaultValue="a">
                      <Radio.Button value="a">ALL</Radio.Button>
                      <Radio.Button value="b">ONLINE</Radio.Button>
                      <Radio.Button value="c">STORES</Radio.Button>
                    </Radio.Group>
                  </div>
                </div>
              </div>
              <div className="ant-list-box table-responsive">
                <table className="width-100">
                  <thead>
                    <tr>
                      <th>COMPANIES</th>
                      <th>MEMBERS</th>
                      <th>BUDGET</th>
                      <th>COMPLETION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((d, index) => (
                      <tr key={index}>
                        <td>
                          <h6>
                            <img
                              src={d.img}
                              alt=""
                              className="avatar-sm mr-10"
                            />{" "}
                            {d.Title}
                          </h6>
                        </td>
                        <td>{d.member}</td>
                        <td>
                          <span className="text-xs font-weight-bold">
                            {d.bud}{" "}
                          </span>
                        </td>
                        <td>
                          <div className="percent-progress">{d.progress}</div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="uploadfile shadow-none">
                <Upload {...uploadProps}>
                  <Button
                    type="dashed"
                    className="ant-full-box"
                    icon={<ToTopOutlined />}
                  >
                    <span className="click">Click to Upload</span>
                  </Button>
                </Upload>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <div className="timeline-box">
                <Title level={5}>Orders History</Title>
                <Paragraph className="lastweek" style={{ marginBottom: 24 }}>
                  this month <span className="bnb2">20%</span>
                </Paragraph>

                <Timeline
                  pending="Recording..."
                  className="timelinelist"
                  reverse={reverse}
                >
                  {timelineList.map((t, index) => (
                    <Timeline.Item color={t.color} key={index}>
                      <Title level={5}>{t.title}</Title>
                      <Text>{t.time}</Text>
                    </Timeline.Item>
                  ))}
                </Timeline>
                <Button
                  type="primary"
                  className="width-100"
                  onClick={() => setReverse(!reverse)}
                >
                  {<MenuUnfoldOutlined />} REVERSE
                </Button>
              </div>
            </Card>
          </Col>
        </Row> */}

        {/* <Row gutter={[24, 0]}>
          <Col xs={24} md={12} sm={24} lg={12} xl={14} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <Row gutter>
                <Col
                  xs={24}
                  md={12}
                  sm={24}
                  lg={12}
                  xl={14}
                  className="mobile-24"
                >
                  <div className="h-full col-content p-20">
                    <div className="ant-muse">
                      <Text>Built by developers</Text>
                      <Title level={5}>Muse Dashboard for Ant Design</Title>
                      <Paragraph className="lastweek mb-36">
                        From colors, cards, typography to complex elements, you
                        will find the full documentation.
                      </Paragraph>
                    </div>
                    <div className="card-footer">
                      <a className="icon-move-right" href="#pablo">
                        Read More
                        {<RightOutlined />}
                      </a>
                    </div>
                  </div>
                </Col>
                <Col
                  xs={24}
                  md={12}
                  sm={24}
                  lg={12}
                  xl={10}
                  className="col-img"
                >
                  <div className="ant-cret text-right">
                    <img src={card} alt="" className="border10" />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col xs={24} md={12} sm={24} lg={12} xl={10} className="mb-24">
            <Card bordered={false} className="criclebox card-info-2 h-full">
              <div className="gradent h-full col-content">
                <div className="card-content">
                  <Title level={5}>Work with the best</Title>
                  <p>
                    Wealth creation is an evolutionarily recent positive-sum
                    game. It is all about who take the opportunity first.
                  </p>
                </div>
                <div className="card-footer">
                  <a className="icon-move-right" href="#pablo">
                    Read More
                    <RightOutlined />
                  </a>
                </div>
              </div>
            </Card>
          </Col>
        </Row> */}
      </div>
    </>
  );
}

export default AdminDashboard;
