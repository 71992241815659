import React, { useEffect, useState, useContext } from "react";
import { Card, Typography, Col, Row, Radio, Button, Form, Input,Select, Tooltip } from "antd";
import { Link } from "react-router-dom";
import Paragraph from "antd/lib/typography/Paragraph";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConfirmationModal } from "../../components/Modals/index";
import AdminDataTable from "../../components/Layouts/sharedAdminLayout/partials/data-table";
import { CrewContext } from "../../context/CrewContext";
import { CrewTypeContext } from "../../context/CrewTypeContext";
import { GenericContext } from "../../context/genericContext";
import { CreateCrewModal, UpdateCrewModal } from "../../components/Modals";
import DataLoader from "../../components/dataLoader";
import { getFormattedDateTime, getFormattedData } from "../../utils";
import FilterDrawer from "../../components/filterDrawer";
import { FilterFilled,  EditOutlined, DeleteOutlined } from "@ant-design/icons";


function CrewManagement() {
  const { createCrewsList, updateCrew, crewsList, getCrewsList, deleteCrew, loading } =
    useContext(CrewContext);
  const {getCrewTypesList, crewTypeLists} = useContext(CrewTypeContext);  
  const { getCountries, countries } = useContext(GenericContext);

  const [filteredData, setFilteredData] = useState(null);
  const [isFilterOpen, setFilterOpen] = useState(false);  
  const [showCreateModal, setshowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const [filteredCrew, setFilteredCrew] = useState([]);
  const [selectedData, setselectedData] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [filterStatus, setFilterStatus] = useState("all");
  const [countryData, setcountryData] = useState([]);
  const [crewTypeData, setcrewTypeData] = useState([]);
  const [page, setPage] = useState(1);
  const { meta, data } = crewsList;
  const paginationConfig = {
    pageSize: meta?.per_page,
    total: meta?.total,
  };

  const [form] = Form.useForm();
  const handleFormFinish = () => {
    const credentials = form.getFieldsValue();
    setFilterOpen(false);
    setFilteredData(credentials);
  };
  const handleResetForm = () => {
    form.resetFields();
    setFilteredData(null);
    form.setFieldsValue({
      q: undefined,
      country_id: undefined,
      crew_type_id: undefined,
    });

    setFilterOpen(false);
  };


  useEffect(() => {
    let filterQuery = "";
    if (filteredData) {
      Object.keys(filteredData).forEach(function (key) {
        if (filteredData[key] && key !== "date") {
          filterQuery += `&${key}=${filteredData[key]}`;
        }
      });
    }

    if(filterStatus=="active"){
      filterQuery += `&is_active=${1}`;
    }
    if(filterStatus=="inactive"){
      filterQuery += `&is_active=${0}`;
    }
    if(filterStatus=="deactivated"){
      filterQuery += `&is_deactivated=${1}`;
    }
    if(filterStatus=="deleted"){
      filterQuery += `&is_deleted=${1}`;
    }
  
    console.log("use Effect Called", page);
    console.log("filter status",filterStatus);
    console.log("use Effect filters", filterQuery);

  
    getCrewsList(page, true, filterQuery);

  }, [page, filteredData]);

  const filterHandler = () => {
    setFilterOpen(!isFilterOpen);
  };


  // useEffect(() => {
  //   const temp = crewsList?.data?.filter((crew) =>
  //     filterStatus === "active"
  //       ? crew?.user?.is_active
  //       : filterStatus === "inactive"
  //       ? !crew?.user?.is_active
  //       : crew
  //   );
  //   console.log("filteredJobs", temp);
  //   setFilteredCrew(temp);
  // }, [filterStatus, data]);

  const handleCreateModal = () => {
    setshowCreateModal(true);
  };
  const handleCloseCreateModal = () => {
    setshowCreateModal(false);
  };
  const handleAddToggle = (data) => {
    createCrewsList(data, page, true);
  };
  // useEffect(() => {
  //   getCrewsList(page, true);
  // }, [page]);


  useEffect(() => {
    setcountryData(Array.isArray(countries) ? countries : countries.data);
  }, [countries]);

  useEffect(() => {
    setcrewTypeData(Array.isArray(crewTypeLists) ? crewTypeLists : crewTypeLists.data);
  }, [crewTypeLists]);


  useEffect(() => {
    getCrewTypesList(1,false);
    getCountries();
  }, []);

  const { Title } = Typography;
  const handlePageChange = (page, pageSize) => {
    setPage(page);
  };
  const onChange = (e) => {
    const status = e.target.value;
    setFilterStatus(status);

    let filterQuery = "";
    if(status=="active"){
      filterQuery += `&is_active=${1}`;
    }
    if(status=="inactive"){
      filterQuery += `&is_active=${0}`;
    }
    if(status=="deactivated"){
      filterQuery += `&is_deactivated=${1}`;
    }
    if(status=="deleted"){
      filterQuery += `&is_deleted=${1}`;
    }
    getCrewsList(page, true, filterQuery);
  };

  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      render: (data, value, i) => (
        <b>{(page == 1 ? 0 : (page - 1) * 10) + (i + 1)}</b>
      ),
    },
    {
      title: "User name",
      dataIndex: "user",
      key: "user",
      render: (data, value) => (
        <span>{`${getFormattedData(data?.firstname)} ${getFormattedData(
          data?.lastname
        )}`}</span>
      ),
    },
    {
      title: "Crew type",
      dataIndex: "crew_type",
      key: "crew_type",
      render: (data, value) => <span>{getFormattedData(data?.name)}</span>,
    },
    {
      title: "Email",
      dataIndex: "user",
      key: "user",
      render: (data, value) => {
        return <span>{getFormattedData(data?.email)}</span>;
      },
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      render: (data, value) => {
        return <span>{getFormattedData(data?.name)}</span>;
      },
    },

    {
      title: "Joined at",
      dataIndex: "created_at",
      key: "created_at",
      render: (data, value) => {
        return <span>{getFormattedDateTime(data)}</span>;
      },
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        const { crew_type, id } = record;
        return (
          <div className="actions">
            <Link to={`crew/${id}`} className="preview">
              <Tooltip title="View crew">
                <FontAwesomeIcon icon={faEye} />
              </Tooltip>
            </Link>
            <span className="update" onClick={() => handleUpdateModal(record)}>
              <Tooltip title="Update Crew">
                <EditOutlined />
              </Tooltip>
            </span>

            <span>
              <Tooltip title="Delete Crew">
                <FontAwesomeIcon
                  icon={faTrash}
                  onClick={() => handleConfirmModal(record)}
                />
              </Tooltip>
            </span>
          </div>
        );
      },
    },
  ];


  const drawerSec = () => (
    <>
      {isFilterOpen && (
        <Form
          form={form}
          className="form"
          initialValues={filteredData}
          onFinish={handleFormFinish}
        >
          <label className="form-lbl">Search Crew:</label>
          <Form.Item name={"q"}>
            <Input type="text" />
          </Form.Item>
          <Form.Item>

          <label className="form-lbl">Country:</label>
          <Form.Item name={"country_id"}>
            <Select
              placeholder="Filter by country"
              name="country_id"
              className="form-select"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {countryData.map((data, index) => (
                <Option value={data.id} key={index}>
                  {data.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <label className="form-lbl">Crew type:</label>
          <Form.Item name={"crew_type_id"}>
            <Select
              placeholder="Filter by crew type"
              name="crew_type_id"
              className="form-select"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {crewTypeData.map((data, index) => (
                <Option value={data.id} key={index}>
                  {data.name}
                </Option>
              ))}
            </Select>
          </Form.Item>


            <Button
              htmlType="submit"
              className="form-btn"
              style={{ minWidth: "100%" }}
            >
              Apply Filters
            </Button>
            <span className="form-rm-btn" onClick={handleResetForm}>
              Reset Filters
            </span>
          </Form.Item>
        </Form>
      )}
    </>
  );


  // Handlers
  const handleConfirmModal = (data) => {
    setselectedData(data);
    setShowConfirmationModal(true);
  };
  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
  };

  const handleUpdateModal = (data) => {
    setselectedData(data);
    setShowUpdateModal(true);
  };
  const handleUpdateModalClose = () => {
    setShowUpdateModal(false);
  };
  const handleUpdateData = (data, id) => {
    updateCrew(data, id, page, true);
  };

  const handleDeleteData = (id) => {
    deleteCrew(id, page, true);
  };

  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            {/* <div className="add-btn">
              <button onClick={handleCreateModal}>Create Crew</button>
            </div> */}
            <Card
              bordered={false}
              className="criclebox cardbody"
              style={{ minHeight: 360 }}
            >
              <div className="project-ant">
                <div>
                  <Title level={5}>Crew</Title>
                  <Paragraph className="lastweek">
                    {/* done this month<span className="blue">40%</span> */}
                  </Paragraph>
                </div>
                <div className="ant-filtertabs mb-24">
                  <div className="antd-pro-pages-dashboard-analysis-style-salesExtra">
                    <Radio.Group onChange={onChange} defaultValue="all">
                      <Radio.Button value="all">ALL</Radio.Button>
                      <Radio.Button value="active">ACTIVE</Radio.Button>
                      <Radio.Button value="inactive">IN-ACTIVE</Radio.Button>
                      <Radio.Button value="deactivated">DE-ACTIVATED</Radio.Button>
                      <Radio.Button value="deleted">DELETED</Radio.Button>
                    </Radio.Group>
                    <Button className="filter-btn" style={{marginLeft:"20px"}} onClick={filterHandler}>
                      <FilterFilled />
                      Filter
                    </Button>

                  </div>
                </div>

              </div>
              <AdminDataTable
                data={data}
                columns={columns}
                pagination={{ ...paginationConfig, onChange: handlePageChange }}
              />
            </Card>
          </Col>
        </Row>
      </div>

      <FilterDrawer
        visible={isFilterOpen}
        toggle={filterHandler}
        content={drawerSec()}
      />


      {showCreateModal && (
        <CreateCrewModal
          handleClose={handleCloseCreateModal}
          addData={handleAddToggle}
        />
      )}

      {showUpdateModal && (
        <UpdateCrewModal
          handleClose={handleUpdateModalClose}
          selectedData={selectedData}
          updateData={handleUpdateData}
        />
      )}


      {showConfirmationModal && (
        <ConfirmationModal
          handleClose={handleConfirmationModalClose}
          title={"Delete Crew?"}
          description={"Are you sure you want to delete this crew?"}
          deleteHandler={handleDeleteData}
          selectedData={selectedData}
        />
      )}
      {loading && <DataLoader />}
    </>
  );
}

export default CrewManagement;
